class APIEndpoints {
  LOGIN = 'login';

  TEAM_PLAYERS = 'team/players';

  CREATE_PLAYER = 'create';
  CREATE_PLAYER_BY_TEAM = 'team/player/add';
  UPLOAD_PLAYER = 'upload';
  PLAYERS = 'players';
  UPDATE_PLAYERS = 'player/update/';
  DELETE_PLAYER = 'player/delete/';
  REMOVE_PLAYER_FROM_TEAM = 'remove/player/from/team/';
  PLAYER_DETAILS = 'player/details/';
  COUNTRIES = 'get/states';
  UPDATE_PROFILE = 'update/profile';

  CREATE_TEAM = 'team/create';
  TEAMS = 'teams';
  UPDATE_TEAMS = 'team/update/';
  DELETE_TEAM = 'team/delete/';
  TEAM_DETAILS = 'team/details/';

  LEADERBOARD_MONTHLY = 'user/leaderboard/monthly';
  LEADERBOARD_ALL = 'user/leaderboard';

  ForgotPasswordSendEmail = "forget/password";
  VerifyOTPCode = "forget/password/verify/code";
  UpdatePassword = "forget/password/update";
}

export default new APIEndpoints();
