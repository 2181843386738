import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler } from '@coreui/react'
import { cilBell, cilEnvelopeOpen, cilList, cilMenu,cilAccountLogout } from '@coreui/icons'

import CIcon from '@coreui/icons-react'

import { AppSidebarNav } from './AppSidebarNav'

import { logoNegative } from 'src/assets/brand/logo-negative'
import { sygnet } from 'src/assets/brand/sygnet'

import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

// sidebar nav config
import navigation from '../_nav'
import { API_ENDPOINT, APP_DOMAIN } from "../environment";
import { authService } from "../services/auth.service";
import { Link } from 'react-router-dom'

const AppSidebar = () => {
  const dispatch = useDispatch()
  const unfoldable = useSelector((state) => state.sidebarUnfoldable)
  const sidebarShow = useSelector((state) => state.sidebarShow)
  let logout = () => {
    authService.signout();

  }
  console.log(authService.userInfo().avatar);
  return (
    <CSidebar
      className='rt-sidebar'
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch({ type: 'set', sidebarShow: visible })
      }}
    >
      <CSidebarBrand className="d-none d-md-flex flex-column" to="/">
          <img src={APP_DOMAIN+'/images/'+authService.userInfo().club_icon} alt="" height={40} />
          <span className='d-block mt-2'>{authService.userInfo().club_name}</span>
        <CIcon className="sidebar-brand-narrow" icon={sygnet} height={35} />
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav items={navigation} />
        </SimpleBar>
      </CSidebarNav>
      {/* <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() => dispatch({ type: 'set', sidebarUnfoldable: !unfoldable })}
      /> */}
      <div className='rt-btnlogout'>
        <Link onClick={logout}>
          <span>LOGOUT</span>
          <CIcon icon={cilAccountLogout} size="lg" />
        </Link>
      </div>
    </CSidebar>
  )
}

export default React.memo(AppSidebar)
