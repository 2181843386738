import axios from 'axios'
import { API_ENDPOINT } from '../environment'

export class HttpService {
  /**
   * Set Token On Header
   * @param token
   */
  static setToken(token) {
    axios.defaults.headers['Authorization'] = `Bearer ${token}`;
    console.log('token', token)
  }

  /**
   * Fetch data from server
   * @param url Endpoint link
   * @return Promise
   */
  get = async (url, params) => {
    const test = await axios.get(`${API_ENDPOINT}/${url}`, {
      params,
    })


    return test
  }
  /**
   * Write data over server
   * @param url Endpoint link
   * @param body Data to send over server
   * @return Promise
   */
  post = async (url, body, options = {}) => {

    const test = await axios.post(`${API_ENDPOINT}/${url}`, body, {
      ...options,
    })

    return test
  }


}
