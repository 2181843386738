import React from "react";
import { Redirect, Route, useHistory } from "react-router-dom";
import { authService } from "../services/auth.service";

// eslint-disable-next-line react/prop-types
const ClubPrivateRoute = ({ children, ...rest }) => {
  console.log(authService.isAuthenticated(),authService.isClub());
  let history = useHistory();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        authService.isAuthenticated() && authService.isClub() ? (children) : console.log('pushed')
      }
    />
  );
};

export default ClubPrivateRoute;
