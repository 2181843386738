import React, { useCallback, useEffect, useState } from "react";

import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle,
  CRow
} from "@coreui/react";
import CIcon from '@coreui/icons-react'
import {  AppHeader, AppSidebar } from "../../components";
import { cilPencil } from '@coreui/icons';
import { authService } from "../../services/auth.service";
import { dashboardService } from "../../services/dashboard.service";
import { useHistory } from "react-router-dom";
import AppSidebarTeam from "../../components/AppSidebarTeam";
import { APP_DOMAIN } from "../../environment";
import Cropper from "react-easy-crop";
import getCroppedImg from "./cropimage";

const Profile = () => {

  const [profileData, setProfileData] = useState({
    full_name: authService.userInfo().name,
    email:authService.userInfo().email,
    club_name: authService.userInfo().club_name,
    password:'',
    country : authService.userInfo().country,
    image : authService.userInfo().avatar,
    club_image : APP_DOMAIN+'/images/'+authService.userInfo().club_logo,
    club_image_icon : APP_DOMAIN+'/images/'+authService.userInfo().club_icon,
    avatar : null,
    club_logo : null,
    club_icon : null,
  });

  const [countries,setCountries] = useState([]);

  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)
  const [showModal,setShowModal] = useState(false)
  const [currentCropping, setCurrentCropping] = useState();

  let history = useHistory()
  useEffect(() => {
    dashboardService.countries({getCountryList: 1}).then(({data}) => {
      if (data.status_code == 200){
        setCountries(data.message)
      }
    })
  }, []);

  let selectAvatar = (e) => {
    let file = e.target.files[0];
    console.log(URL.createObjectURL(file))
    setProfileData({...profileData,image:URL.createObjectURL(file),avatar:file})

  }

  let selectLogo = (e) => {
    let file = e.target.files[0];
    console.log(file);

    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (e) {
      setCurrentCropping('logo')
      setProfileData({...profileData,club_image:URL.createObjectURL(file)})
      setShowModal(true)
    }

  }

  let blobToFile = (theBlob, fileName) => {
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
  }

  let selectIcon = (e) => {
    let file = e.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (e) {
      setCurrentCropping('icon')
      setProfileData({...profileData,club_image_icon:URL.createObjectURL(file)})
      setShowModal(true)

    }
  }

  let updateProfile = (e ) => {
    console.log('test');
    e.preventDefault();
    let formData = new FormData();
    formData.append('avatar',profileData.avatar);
    formData.append('club_logo',profileData.club_logo);
    formData.append('club_icon',profileData.club_icon);
    formData.append('full_name',profileData.full_name);
    formData.append('country',profileData.country);
    formData.append('password',profileData.password);
    formData.append('club_name',profileData.club_name);

    dashboardService.updateProfile(formData).then(({data} ) => {
      if (data.status_code == 200){
        authService.setUpdatedUserInfo(data.user)
        alert('Updated Successfully')
        if (authService.userInfo().role_id == 4) {
          history.push('team/dashboard')
        }else{
          history.push('dashboard')
        }
      }
    });
  }

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const showCroppedImage = useCallback(async (e) => {
    e.preventDefault()
    try {
      const croppedImage = await getCroppedImg(
        currentCropping == 'logo' ? profileData.club_image : profileData.club_image_icon,
        croppedAreaPixels,
        rotation
      )
      if (currentCropping == 'logo') {
        let file = blobToFile(croppedImage, 'logo')
        setProfileData({ ...profileData, club_image: URL.createObjectURL(file), club_logo: file })
      }else{
        let file = blobToFile(croppedImage, 'icon')
        setProfileData({ ...profileData, club_image_icon: URL.createObjectURL(file), club_icon: file })
      }
    } catch (e) {
      console.error(e)
    }
    setShowModal(false)
  }, [croppedAreaPixels, rotation])

  const onClose = useCallback(() => {
    setCroppedImage(null)
  }, [])

  return (
    <div>
      {authService.userInfo().role_id == 4? <AppSidebarTeam /> : <AppSidebar />}
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <div className="rt-signinformholder rt-profileformholder rt-editprofileform py-5">
            <CForm onSubmit={updateProfile}>
              <CRow>
                <CCol sm={12} lg={6} style={{textAlign:'center',margin:'0px auto'}}>
                  <h2>User Information</h2>
                </CCol>
                <CCol sm={12} lg={12}>
                  <figure className="rt-roundimage rt-profileimage">
                    <img src={profileData.image} alt="" height={40} />
                    <CFormLabel className="rt-uploadimage">
                      <CFormInput type="file" name={'disctracted'} onChange={selectAvatar}/>
                      <CIcon icon={cilPencil} size="lg" />
                    </CFormLabel>
                  </figure>
                </CCol>
                <CCol sm={12} lg={6}>
                  <div className="form-group">
                    <div className="rt-fieldholder rt-fieldwithlabel">
                      <CFormInput type="text0" name={'disctracted'} id="exampleInput1" value={profileData.full_name} onChange={e => setProfileData({...profileData,full_name:e.target.value})}  aria-describedby="emailHelp" required={true}/>
                      <CFormLabel htmlFor="exampleInput1">FULL NAME</CFormLabel>
                    </div>
                  </div>
                </CCol>
                <CCol sm={12} lg={6}>
                  <div className="form-group">
                    <div className="rt-fieldholder rt-fieldwithlabel">
                      <CFormInput type="text" name={'disctracted'} id="exampleInput1" value={profileData.email} disabled={true}/>
                      <CFormLabel htmlFor="exampleInput1">Email</CFormLabel>
                    </div>
                  </div>
                </CCol>
                <CCol sm={12} lg={6}>
                  <div className="form-group">
                    <div className="rt-fieldholder rt-fieldwithlabel">
                      <CFormInput type="text" name={'disctracted'} id="exampleInput1" value={profileData.password} onChange={e => setProfileData({...profileData,password:e.target.value})}  aria-describedby="emailHelp"/>
                      <CFormLabel htmlFor="exampleInput1">Password</CFormLabel>
                    </div>
                  </div>
                </CCol>
                <CCol sm={12} lg={6}>
                  <div className="form-group">
                    <div className="rt-fieldholder rt-fieldwithlabel">
                      <div className="rt-select">
                        <CFormSelect  name={'disctracted'} value={profileData.country} onChange={e => setProfileData({...profileData,country: e.target.value})}>
                          <option>Please Select Country</option>
                          {countries.map((country)=> {
                            return (<option key={country.id} value={country.id}>{country.name}</option>)
                          })}
                        </CFormSelect>
                      </div>
                      <CFormLabel htmlFor="exampleInputPassword1">COUNTRY</CFormLabel>
                    </div>
                  </div>
                </CCol>

                {authService.userInfo().role_id == 3 ?
                  <>
                    <CCol sm={12} lg={12} style={{textAlign:'center',margin:'0px auto'}}>
                      <h2>Club Information</h2>
                    </CCol>
                    <CCol sm={12} lg={6}>
                      <figure className="rt-roundimage rt-profileimage">
                        <img src={profileData.club_image} alt="" height={40} />
                        <CFormLabel className="rt-uploadimage">
                          <CFormInput type="file" name={'disctracted'} onChange={selectLogo}/>
                          <CIcon icon={cilPencil} size="lg" />
                        </CFormLabel>
                      </figure>
                    </CCol>
                    <CCol sm={12} lg={6}>
                      <figure className="rt-roundimage rt-profileimage">
                        <img src={profileData.club_image_icon} alt="" height={40} />
                        <CFormLabel className="rt-uploadimage">
                          <CFormInput type="file" name={'disctracted'} onChange={selectIcon}/>
                          <CIcon icon={cilPencil} size="lg" />
                        </CFormLabel>
                      </figure>
                    </CCol>
                    <CCol sm={12} lg={6}>
                      <div className="form-group">
                        <div className="rt-fieldholder rt-fieldwithlabel">
                          <CFormInput type="text0" name={'disctracted'} id="exampleInput1" value={profileData.club_name} onChange={e => setProfileData({...profileData,club_name:e.target.value})}  aria-describedby="emailHelp" required={true}/>
                          <CFormLabel htmlFor="exampleInput1">Club Name</CFormLabel>
                        </div>
                      </div>
                    </CCol>
                    <CCol sm={12} lg={6}>
                      <div className="form-group">
                        <div className="rt-fieldholder rt-fieldwithlabel">
                          <CFormInput type="text0" name={'disctracted'} id="exampleInput1" value={authService.userInfo().user_name} disabled={true} aria-describedby="emailHelp"/>
                          <CFormLabel htmlFor="exampleInput1">Username</CFormLabel>
                        </div>
                      </div>
                    </CCol>
                    <CCol sm={12} lg={6}>
                      <div className="form-group">
                        <div className="rt-fieldholder rt-fieldwithlabel">
                          <CFormInput type="text0" name={'disctracted'} id="exampleInput1" value={authService.userInfo().total_users_count} disabled={true} aria-describedby="emailHelp"/>
                          <CFormLabel htmlFor="exampleInput1">Allowed Users</CFormLabel>
                        </div>
                      </div>
                    </CCol>
                    <CCol sm={12} lg={6}>
                      <div className="form-group">
                        <div className="rt-fieldholder rt-fieldwithlabel">
                          <CFormInput type="text0" name={'disctracted'} id="exampleInput1" value={authService.userInfo().total_teams_count} disabled={true} aria-describedby="emailHelp"/>
                          <CFormLabel htmlFor="exampleInput1">Allowed Teams</CFormLabel>
                        </div>
                      </div>
                    </CCol>

                  </>
                  :''}

                <CCol sm={12} lg={12} className="text-center d-flex justify-content-center align-items-center">
                  <CCol sm={12} lg={6}>
                    <CButton type="submit" color=" rt-btn rt-btn-lg rt-black-btn mt-4">
                      Update
                    </CButton>
                  </CCol>
                </CCol>
              </CRow>
            </CForm>
          </div>
        </div>
        {/* <AppFooter /> */}
      </div>
      <CModal visible={showModal}>
        <CModalHeader>
          <CModalTitle>Crop Your Image</CModalTitle>
        </CModalHeader>
        <CModalBody style={{width:'500px',height:'400px'}}>

          <Cropper
            image={currentCropping == 'logo' ? profileData.club_image : profileData.club_image_icon}
            crop={crop}
            rotation={rotation}
            cropSize={currentCropping == 'logo' ? {height:50,width:250}: {height:50,width:50}}
            zoom={zoom}
            aspect={4 / 3}
            onCropChange={setCrop}
            onRotationChange={setRotation}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </CModalBody>
        <CModalFooter>
          <button  onClick={e => showCroppedImage(e)} >
            Save Changes
          </button>
        </CModalFooter>
      </CModal>
    </div>
  )
}

export default Profile
