import React from "react";
import { Redirect, Route } from "react-router-dom";
import { authService } from "../services/auth.service";

// eslint-disable-next-line react/prop-types
const TeamPrivateRoute = ({ children, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location }) =>

        authService.isAuthenticated() && authService.isTeam() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
};

export default TeamPrivateRoute;
