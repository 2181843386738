import React, { lazy, useEffect, useState } from "react";
import {
  CButton, CForm, CFormInput, CFormLabel, CFormSelect
} from "@coreui/react";

import { AppContent, AppFooter, AppHeader, AppSidebar } from "../../components";
import { Link, useHistory, useParams } from "react-router-dom";
import { dashboardService } from "../../services/dashboard.service";
import Select from 'react-select'

const EditTeam = () => {

  const [userData, setUserData] = useState({
    firstName:'',
    email:'',
    password:'',
    lastName:'',
    users:[]
  });
  let history = useHistory();
  let params = useParams();
  const [users, setUsers] = useState([]);
  const [options, setOptions] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoadedMembers, setIsLoadedMembers] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);


  useEffect(async () => {
    await playerDetails()
    await allPlayers()
  }, []);

  let playerDetails = () => {
    console.log(params);
    dashboardService.getTeamDetails(params.id).then(({ data }) => {
      console.log(data);
      if (data.status_code == 200) {
        var selected = [];
        var selectedIds = [];
        data.members.map((v) => {
          selected.push({ value: v.id, label: v.full_name + '-'+ v.email })
          selectedIds.push(v.id)
        })
        setUsers(selected)
        setUserData({
          firstName: data.user.full_name,
          lastName: data.user.lastName,
          email: data.user.email,
          password: '',
          users:selectedIds
        })
        setIsLoadedMembers(true)
      }
    })
  }

  let allPlayers = () => {
    dashboardService.allPlayersForTeamCreate().then(({data}) => {
      var option = [];
      if (data.status_code == 200){
        data.users.map((v) => {
          option.push({ value: v.id, label: v.full_name + '-'+ v.email })
        })
        setOptions(option);
        setIsLoaded(true)
      }
    })

  }

  let selectUsers = (e) => {
    let value = Array.from(e.target.selectedOptions, option => option.value);
    setUserData({...userData,users: value});
    console.log(value);
  }

  let update = (e) => {
    e.preventDefault();
    setDisableBtn(true)
    if (userData.users.length <= 0){
      setDisableBtn(false)
      alert('Please select at least one player')
      return 0;
    }
    dashboardService.updateTeam(userData,params.id).then(({data}) => {
      console.log(data);
      if (data.status_code == 200){
        history.push('/team/all')
      }
    })

  }

  let onMemberChange = async (e) => {
    var members = [];
    await e.map((v) => {
      members.push(v.value)
    })

    setUserData({...userData,users: members});
  }

  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <div className="rt-signinformholder rt-addplayerform py-5">
            <CForm onSubmit={e => update(e)}>
              <div className="form-group">
                <div className="rt-fieldholder rt-fieldwithlabel">
                  <CFormInput type="text" id="exampleInput1" value={userData.firstName} aria-describedby="emailHelp" onChange={e => setUserData({...userData,firstName: e.target.value})} required={true}/>
                  <CFormLabel htmlFor="exampleInput1">Name</CFormLabel>
                </div>
              </div>
              <div className="form-group">
                <div className="rt-fieldholder rt-fieldwithlabel">
                  <CFormInput type="email" id="exampleInputEmail1" value={userData.email} aria-describedby="emailHelp" onChange={e => setUserData({...userData,email: e.target.value})} required={true}/>
                  <CFormLabel htmlFor="exampleInputEmail1">Email address</CFormLabel>
                </div>
              </div>
              <div className="form-group">
                <div className="rt-fieldholder rt-fieldwithlabel">
                  <CFormInput type="password" id="exampleInputPassword1" onChange={e => setUserData({...userData,password: e.target.value})}/>
                  <CFormLabel htmlFor="exampleInputPassword1">Email Password</CFormLabel>
                </div>
              </div>
              <div className="form-group">
                <div className="rt-fieldholder rt-fieldwithlabel rt-selecttow">
                  {isLoaded && isLoadedMembers?
                  <Select options={options}
                          onChange={onMemberChange}
                          name="members"
                          defaultValue={users}
                          isMulti
                  />:''}
                  {/* <CFormLabel htmlFor="exampleInputPassword1">Select</CFormLabel> */}
                </div>
              </div>

              <CButton disabled={disableBtn} type="submit" color=" rt-btn rt-btn-lg mt-4">
                Update
              </CButton>
            </CForm>
          </div>
        </div>
        {/* <AppFooter /> */}
      </div>
    </div>
  )
}

export default EditTeam
