import { HttpService } from "./base.service";
import APIEndpoints from "./APIEndpoints";
import {authService} from "./auth.service";

class LeaderboardService extends HttpService {
  constructor() {
    super();
    HttpService.setToken(authService.authToken())
    this.prefix = '';
  }

  monthly = (page = 1) => this.get(`${this.prefix}${APIEndpoints.LEADERBOARD_MONTHLY}?page=${page}`);

  allTime = (page = 1) => this.get(`${this.prefix}${APIEndpoints.LEADERBOARD_ALL}?page=${page}`);

  monthlyByTeam = (page = 1,teamId = '') => this.get(`${this.prefix}${APIEndpoints.LEADERBOARD_MONTHLY}?page=${page}&teamId=${teamId}&teamStats=1`);

  allTimeByTeam = (page = 1,teamId = '') => this.get(`${this.prefix}${APIEndpoints.LEADERBOARD_ALL}?page=${page}&teamId=${teamId}&teamStats=1`);


  monthlyDownloadCsv = () => this.get(`${this.prefix}${APIEndpoints.LEADERBOARD_MONTHLY}?downloadCsv=1`);

  allTimeDownloadCsv = () => this.get(`${this.prefix}${APIEndpoints.LEADERBOARD_ALL}?downloadCsv=1`);

  monthlyByTeamDownloadCsv = (teamId = '') => this.get(`${this.prefix}${APIEndpoints.LEADERBOARD_MONTHLY}?teamId=${teamId}&teamStats=1&downloadCsv=1`);

  allTimeByTeamDownloadCsv = (teamId = '') => this.get(`${this.prefix}${APIEndpoints.LEADERBOARD_ALL}?teamId=${teamId}&teamStats=1&downloadCsv=1`);



}

export const leaderboardService = new LeaderboardService();
