import React, { lazy, useEffect, useState } from "react";
import {
  CButton, CForm, CFormInput, CFormLabel, CFormSelect
} from "@coreui/react";

import { AppContent, AppFooter, AppHeader, AppSidebar } from "../../components";
import { Link, useHistory, useParams } from "react-router-dom";
import { dashboardService } from "../../services/dashboard.service";

const EditPlayer = () => {

  const [userData, setUserData] = useState({
    firstName:'',
    email:'',
    password:'',
    lastName:'',
    gender:'',
    position:''
  });
  let history = useHistory();
  let params = useParams();

  useEffect(() => {
    playerDetails()
  }, []);

  let playerDetails = () => {
    console.log(params);
    dashboardService.getUserDetails(params.id).then(({ data }) => {
      console.log(data);
      if (data.status_code == 200) {
        setUserData({
          firstName: data.user.full_name,
          lastName: data.user.lastName,
          email: data.user.email,
          password: '',
          gender:data.user.gender,
          position:data.user.position
        })
      }
    })
  }

  let update = (e) => {
    e.preventDefault();
    dashboardService.updateUser(userData,params.id).then(({data}) => {
      console.log(data);
      if (data.status_code == 200){
        history.push('/player-all')
      }
    })

  }

  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <div className="rt-signinformholder rt-addplayerform py-5">
            <CForm onSubmit={e => update(e)}>
              <div className="form-group">
                <div className="rt-fieldholder rt-fieldwithlabel">
                  <CFormInput type="text" id="exampleInput1" value={userData.firstName} aria-describedby="emailHelp" onChange={e => setUserData({...userData,firstName: e.target.value})} required={true}/>
                  <CFormLabel htmlFor="exampleInput1">Name</CFormLabel>
                </div>
              </div>
              <div className="form-group">
                <div className="rt-fieldholder rt-fieldwithlabel">
                  <CFormInput type="email" id="exampleInputEmail1" value={userData.email} aria-describedby="emailHelp" onChange={e => setUserData({...userData,email: e.target.value})} required={true}/>
                  <CFormLabel htmlFor="exampleInputEmail1">Email address</CFormLabel>
                </div>
              </div>
              <div className="form-group">
                <div className="rt-fieldholder rt-fieldwithlabel">
                  <div className="rt-select">
                    <CFormSelect id="position" onChange={e => setUserData({...userData,gender: e.target.value})} value={userData.gender} required={true}>
                      <option value="">Please Select</option>
                      <option value="he">He</option>
                      <option value="she">She</option>
                      <option value="they">They</option>
                    </CFormSelect>
                  </div>
                  <CFormLabel htmlFor="gender">Gender</CFormLabel>
                </div>
              </div>
              <div className="form-group">
                <div className="rt-fieldholder rt-fieldwithlabel">
                  <div className="rt-select">
                    <CFormSelect id="position" onChange={e => setUserData({...userData,position: e.target.value})} value={userData.position} required={true}>
                      <option value="">Please Select</option>
                      <option value="Goalkeeper">Goalkeeper</option>
                      <option value="Defender">Defender</option>
                      <option value="Midfielder">Midfielder</option>
                      <option value="Forward">Forward</option>
                    </CFormSelect>
                  </div>
                  <CFormLabel htmlFor="position">Position</CFormLabel>
                </div>
              </div>
              <div className="form-group">
                <div className="rt-fieldholder rt-fieldwithlabel">
                  <CFormInput type="password" id="exampleInputPassword1" onChange={e => setUserData({...userData,password: e.target.value})}/>
                  <CFormLabel htmlFor="exampleInputPassword1">Email Password</CFormLabel>
                </div>
              </div>
              <CButton type="submit" color=" rt-btn rt-btn-lg">
                Update
              </CButton>
            </CForm>
          </div>
        </div>
        {/* <AppFooter /> */}
      </div>
    </div>
  )
}

export default EditPlayer
