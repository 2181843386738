import React, { lazy, useState } from "react";

import {
  CAvatar,
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CForm,
  CFormCheck,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormText,
  CProgress,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CWidgetStatsA
} from "@coreui/react";
import { CChartBar, CChartLine } from "@coreui/react-chartjs";
import { getStyle, hexToRgba } from '@coreui/utils'
import CIcon from '@coreui/icons-react'
import {
  cibCcAmex,
  cibCcApplePay,
  cibCcMastercard,
  cibCcPaypal,
  cibCcStripe,
  cibCcVisa,
  cibGoogle,
  cibFacebook,
  cibLinkedin,
  cifBr,
  cifEs,
  cifFr,
  cifIn,
  cifPl,
  cifUs,
  cibTwitter,
  cilCloudDownload,
  cilPeople,
  cilUser,
  cilUserFemale, cilArrowBottom, cilOptions, cilArrowTop
} from "@coreui/icons";

import avatar1 from 'src/assets/images/avatars/1.jpg'
import avatar2 from 'src/assets/images/avatars/2.jpg'
import avatar3 from 'src/assets/images/avatars/3.jpg'
import avatar4 from 'src/assets/images/avatars/4.jpg'
import avatar5 from 'src/assets/images/avatars/5.jpg'
import avatar6 from 'src/assets/images/avatars/6.jpg'
import { AppContent, AppFooter, AppHeader, AppSidebar } from "../../components";
import { Link, useHistory } from "react-router-dom";
import { dashboardService } from "../../services/dashboard.service";
import AppSidebarTeam from "../../components/AppSidebarTeam";

const AddPlayer = () => {

  const [userData, setUserData] = useState({
    firstName:'',
    email:'',
    password:'',
    lastName:''
  });
  let history = useHistory();
  let addPlayer = (e) => {
    e.preventDefault();
    userData.user_name = userData.firstName
    dashboardService.createUserByTeam(userData).then(({data}) => {
      console.log(data);
      if (data.status_code == 200){
        history.push('/team/player-all')
      }else{
        alert(data.message)
      }
    })

  }

  return (
    <div>
      <AppSidebarTeam />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <div className="rt-signinformholder rt-addplayerform py-5">
            <CForm onSubmit={e => addPlayer(e)}>
              <div className="form-group">
                <div className="rt-fieldholder rt-fieldwithlabel">
                  <CFormInput type="text" id="exampleInput1" aria-describedby="emailHelp" onChange={e => setUserData({...userData,firstName: e.target.value})} required={true}/>
                  <CFormLabel htmlFor="exampleInput1">Name</CFormLabel>
                </div>
              </div>
              <div className="form-group">
                <div className="rt-fieldholder rt-fieldwithlabel">
                  <CFormInput type="email" id="exampleInputEmail1" aria-describedby="emailHelp" onChange={e => setUserData({...userData,email: e.target.value})} required={true}/>
                  <CFormLabel htmlFor="exampleInputEmail1">Email address</CFormLabel>
                </div>
              </div>
              <div className="form-group">
                <div className="rt-fieldholder rt-fieldwithlabel">
                  <div className="rt-select">
                    <CFormSelect id="position" onChange={e => setUserData({...userData,gender: e.target.value})} required={true}>
                      <option value="">Please Select</option>
                      <option value="he">He</option>
                      <option value="she">She</option>
                      <option value="they">They</option>
                    </CFormSelect>
                  </div>
                  <CFormLabel htmlFor="gender">Gender</CFormLabel>
                </div>
              </div>
              <div className="form-group">
                <div className="rt-fieldholder rt-fieldwithlabel">
                  <div className="rt-select">
                    <CFormSelect id="position" onChange={e => setUserData({...userData,position: e.target.value})} required={true}>
                      <option value="">Please Select</option>
                      <option value="Goalkeeper">Goalkeeper</option>
                      <option value="Defender">Defender</option>
                      <option value="Midfielder">Midfielder</option>
                      <option value="Forward">Forward</option>
                    </CFormSelect>
                  </div>
                  <CFormLabel htmlFor="position">Position</CFormLabel>
                </div>
              </div>
              <div className="form-group">
                <div className="rt-fieldholder rt-fieldwithlabel">
                  <CFormInput type="password" id="exampleInputPassword1" onChange={e => setUserData({...userData,password: e.target.value})} required={true}/>
                  <CFormLabel htmlFor="exampleInputPassword1">Email Password</CFormLabel>
                </div>
              </div>
              <CButton type="submit" className="rt-btn rt-btn-lg">
                Create
              </CButton>
            </CForm>
          </div>
        </div>
        {/* <AppFooter /> */}
      </div>
    </div>
  )
}

export default AddPlayer
