import React, { lazy, useEffect, useState } from "react";

import {
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
  CTableHead,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableRow, CWidgetStatsA
} from "@coreui/react";
import { AppContent, AppFooter, AppHeader, AppSidebar } from "../../components";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { leaderboardService } from "../../services/leaderboard.service";
import ReactPaginate from "react-paginate";
import { authService } from "../../services/auth.service";
import AppSidebarTeam from "../../components/AppSidebarTeam";
import CIcon from "@coreui/icons-react";
import { cilCloudDownload } from "@coreui/icons";

const TeamLeaderBoard = (props) => {
  const [allTime, setAllTime] = useState([]);
  const [monthly, setMonthly] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [totalPageMonthly, setTotalPageMonthly] = useState(0);
  const [allTimeInit, setAllTimeInit] = useState(false);
  const [topThree, setTopThree] = useState([]);
  const [topThreeAll, setTopThreeAll] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeKey , setActiveKey] = useState(1)
  let params = useParams();
  useEffect(() => {
    leaderboardService.monthlyByTeam(1,params.teamId).then(({data}) => {
      setMonthly(data.data)
      setTopThree(data.data.slice(0, 3))
      setTotalPageMonthly(data.totalPages)
      setLoading(false)
    })
  }, []);

  let getAllTime = (e) => {
    let page = e.selected ?? 0;
    leaderboardService.allTimeByTeam(page+1,params.teamId).then(({data}) => {
      setAllTime(data.data)
      if (topThreeAll.length == 0){
        setTopThreeAll(data.data.slice(0, 3))
      }
      setTotalPage(data.totalPages)
      setLoading(false)
    })
  }
  let getMonthly = (e) => {
    let page = e.selected ?? 0;
    leaderboardService.monthly(page+1,params.teamId).then(({data}) => {
      setMonthly(data.data)
      setTotalPageMonthly(data.totalPages)
    })
  }

  let allTimeDataInit = (e) => {
    console.log(allTimeInit);
    if (!allTimeInit) {
      setAllTimeInit(true);
      setLoading(true)
      getAllTime(e)
    }
    setActiveKey(2)
  }

  let downloadStats = () => {
    if (activeKey == 1){
      leaderboardService.monthlyByTeamDownloadCsv(params.teamId).then(({data}) => {
        prepareAndDownloadCsv(data.data)
      })
    }else{
      leaderboardService.allTimeByTeamDownloadCsv(params.teamId).then(({data}) => {
        prepareAndDownloadCsv(data.data)
      })
    }
  }

  let prepareAndDownloadCsv = (string) => {
    const url = window.URL.createObjectURL(new Blob([string]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Leaderboard Report.csv');
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  return (
    <div>
      {authService.userInfo().role_id == 4? <AppSidebarTeam /> : <AppSidebar />}
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <div className="rt-leaderboard">
            <div className="rt-pageheading">
              <div className="rt-title">
                <h2>Team leaderboard</h2>
              </div>
              <CNav variant="tabs" role="tablist" className="rt-navtabs">
                <CNavItem>
                  <CNavLink
                    href="javascript:void(0);"
                    onClick={(e) => downloadStats(e)}
                  >
                    <CIcon icon={cilCloudDownload} />
                  </CNavLink>
                </CNavItem>
                <CNavItem>
                  <CNavLink
                    href="javascript:void(0);"
                    active={activeKey === 1}
                    onClick={() => setActiveKey(1)}
                  >
                    Monthly
                  </CNavLink>
                </CNavItem>
                <CNavItem>
                  <CNavLink
                    href="javascript:void(0);"
                    active={activeKey === 2}
                    onClick={(e) => allTimeDataInit(e)}
                  >
                    All Time
                  </CNavLink>
                </CNavItem>
              </CNav>
            </div>
            <div className="rt-leaderboardarea">
              <div className="rt-teamboard">
                {activeKey == 1 ? topThree.map((d, key) => {
                    // eslint-disable-next-line react/jsx-key
                    return (<div className="rt-themecardbox">
                          <figure className="rt-roundimage">
                            <img src={d.avatar} alt=""/>
                          </figure>
                          <div className="rt-themecontent">
                            <span>{key + 1}{key + 1 == 1 ? 'st' : (key + 1 == 2 ? 'nd' : 'rd')}</span>
                            <h4>{d.name}</h4>
                            <em>{d.completed_sessions}</em>
                          </div>
                        </div>)
                  })
                  : topThreeAll.map((d, key) => {
                    // eslint-disable-next-line react/jsx-key
                    return (<div className="rt-themecardbox">
                          <figure className="rt-roundimage">
                            <img src={d.avatar} alt=""/>
                          </figure>
                          <div className="rt-themecontent">
                            <span>{key + 1}{key + 1 == 1 ? 'st' : (key + 1 == 2 ? 'nd' : 'rd')}</span>
                            <h4>{d.name}</h4>
                            <em>{d.completed_sessions}</em>
                          </div>
                        </div>)
                  })}

              </div>
              <CTabContent>
                <CTabPane role="tabpanel" aria-labelledby="home-tab" visible={activeKey === 1}>
                  <div className="rt-themetableholder">
                    <CTable striped>
                      <CTableHead>
                        <CTableRow>
                          {/* <CTableHeaderCell scope="col">#</CTableHeaderCell>s */}
                          <CTableHeaderCell scope="col">TROPHIES</CTableHeaderCell>
                          <CTableHeaderCell scope="col">Club MEMBERS</CTableHeaderCell>
                          <CTableHeaderCell scope="col">LEVEL</CTableHeaderCell>
                          <CTableHeaderCell scope="col">POINTS</CTableHeaderCell>
                          {/*                   <CTableHeaderCell scope="col">Last Login</CTableHeaderCell>
                          <CTableHeaderCell scope="col">Action</CTableHeaderCell> */}
                        </CTableRow>
                      </CTableHead>
                      <CTableBody>
                        {monthly.map((d )=> {
                          // eslint-disable-next-line react/jsx-key
                          return (<CTableRow>
                            <CTableHeaderCell scope="col"> <figure className="rt-roundimage">
                              <img src={d.trophy} alt="" />
                            </figure>
                            </CTableHeaderCell>
                            <CTableHeaderCell scope="col">{d.name}</CTableHeaderCell>
                            <CTableHeaderCell scope="col">{d.level}</CTableHeaderCell>
                            <CTableHeaderCell scope="col">{d.completed_sessions}</CTableHeaderCell>
                          </CTableRow>)
                        })}
                      </CTableBody>
                    </CTable>
                  </div>
                  {totalPageMonthly > 1 ?
                    <ReactPaginate
                      previousLabel={'<'}
                      nextLabel={'>'}
                      breakLabel={'...'}
                      breakClassName={'page-item'}
                      nextClassName={'rt-next page-item'}
                      previousClassName={'rt-prev page-item'}
                      pageCount={totalPageMonthly}
                      pageClassName={'page-item'}
                      pageLinkClassName={'page-link'}
                      nextLinkClassName={'page-link'}
                      previousLinkClassName={'page-link'}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={(e)=> getMonthly(e)}
                      containerClassName={'rt-pagination pagination'}
                      activeClassName={'active'}
                    /> : ''}
                </CTabPane>
                <CTabPane role="tabpanel" aria-labelledby="profile-tab" visible={activeKey === 2}>
                  <div className="rt-themetableholder">
                    <CTable striped>
                      <CTableHead>
                        <CTableRow>
                          {/* <CTableHeaderCell scope="col">#</CTableHeaderCell>s */}
                          <CTableHeaderCell scope="col">TROPHIES</CTableHeaderCell>
                          <CTableHeaderCell scope="col">Club MEMBERS</CTableHeaderCell>
                          <CTableHeaderCell scope="col">LEVEL</CTableHeaderCell>
                          <CTableHeaderCell scope="col">POINTS</CTableHeaderCell>
                          {/*                   <CTableHeaderCell scope="col">Last Login</CTableHeaderCell>
                          <CTableHeaderCell scope="col">Action</CTableHeaderCell> */}
                        </CTableRow>
                      </CTableHead>
                      <CTableBody>
                        {allTime.map((d )=> {
                          // eslint-disable-next-line react/jsx-key
                          return (<CTableRow>
                            <CTableHeaderCell scope="col"> <figure className="rt-roundimage">
                              <img src={d.trophy} alt="" />
                            </figure>
                            </CTableHeaderCell>
                            <CTableHeaderCell scope="col">{d.name}</CTableHeaderCell>
                            <CTableHeaderCell scope="col">{d.level}</CTableHeaderCell>
                            <CTableHeaderCell scope="col">{d.completed_sessions}</CTableHeaderCell>
                          </CTableRow>)
                        })}
                      </CTableBody>
                    </CTable>
                  </div>
                  {totalPage > 1 ?
                    <ReactPaginate
                      previousLabel={'<'}
                      nextLabel={'>'}
                      breakLabel={'...'}
                      breakClassName={'page-item'}
                      nextClassName={'rt-next page-item'}
                      previousClassName={'rt-prev page-item'}
                      pageCount={totalPage}
                      pageClassName={'page-item'}
                      pageLinkClassName={'page-link'}
                      nextLinkClassName={'page-link'}
                      previousLinkClassName={'page-link'}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={(e)=> getAllTime(e)}
                      containerClassName={'rt-pagination pagination'}
                      activeClassName={'active'}
                    /> : ''}
                </CTabPane>
              </CTabContent>
            </div>
          </div>
        </div>
        {/* <AppFooter /> */}
      </div>
    </div>
  )
}

export default TeamLeaderBoard
