import React, { lazy, useEffect, useState } from "react";

import {
  CAvatar,
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader, CCardText, CCardTitle,
  CCol, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle,
  CProgress,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow, CWidgetStatsA
} from "@coreui/react";
import { CChartBar, CChartLine } from "@coreui/react-chartjs";
import { getStyle, hexToRgba } from '@coreui/utils'
import CIcon from '@coreui/icons-react'
import {
  cibCcAmex,
  cibCcApplePay,
  cibCcMastercard,
  cibCcPaypal,
  cibCcStripe,
  cibCcVisa,
  cibGoogle,
  cibFacebook,
  cibLinkedin,
  cifBr,
  cifEs,
  cifFr,
  cifIn,
  cifPl,
  cifUs,
  cibTwitter,
  cilCloudDownload,
  cilPeople,
  cilUser,
  cilUserFemale, cilArrowBottom, cilOptions, cilArrowTop, cilTrash, cilPencil
} from "@coreui/icons";

import avatar1 from 'src/assets/images/avatars/1.jpg'
import avatar2 from 'src/assets/images/avatars/2.jpg'
import avatar3 from 'src/assets/images/avatars/3.jpg'
import avatar4 from 'src/assets/images/avatars/4.jpg'
import avatar5 from 'src/assets/images/avatars/5.jpg'
import avatar6 from 'src/assets/images/avatars/6.jpg'
import { AppContent, AppFooter, AppHeader, AppSidebar } from "../../components";
import { Link } from "react-router-dom";
import { authService } from "../../services/auth.service";
import { HttpService } from "../../services/base.service";
import { dashboardService } from "../../services/dashboard.service";

const Dashboard = () => {

  const [recentUsers, setRecentUsers] = useState([]);
  const [authUser, setAuthUser] = useState({
    added_users_count:'',
    total_users_count:'',
    total_teams_count:'',
    added_teams_count:'',

  });

  useEffect( () => {
    allPlayers()
  }, []);

  let allPlayers = () => {
    dashboardService.allPlayers().then(async ({data}) => {
      console.log(data);
      if (data.status_code == 200){
        setRecentUsers(data.users.data)
        setAuthUser(data.auth_user)
      }else{
        alert(data.message)
      }
    }).catch(e => {
      alert('Invalid Credentials')
    })
  }

  let deleteUser = (id) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Do you really want to delete?') ===  true){
      dashboardService.deletePlayer(id).then(({ data }) => {
        console.log(data);
        if (data.status_code == 200){
          allPlayers()
        }
      })
    }
  }
  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <>
            <CRow>
              <CCol sm={6} lg={3}>
                <Link to="player-all">
                  <CWidgetStatsA
                    className="rt-stats"
                    value={
                      <>
                        {authUser.added_users_count}
                        <br/>
                      </>
                    }
                    title={
                      <>
                        <br/>
                        <h2 className='rt-statstitle'>Total Players </h2>
                      </>
                    }
                  />
                </Link>
              </CCol>
              <CCol sm={6} lg={3}>
                <Link to="team/all">
                  <CWidgetStatsA
                    className="rt-stats"
                    value={
                      <>
                        {authUser.added_teams_count}
                      </>
                    }
                    title={
                      <>
                        <br/>
                        <h2 className='rt-statstitle'>Total Teams</h2>
                      </>
                    }
                  />
                </Link>
              </CCol>

              <CCol sm={6} lg={3}>
                <Link to="player-add">

                  <CWidgetStatsA
                    className="rt-stats"
                    value={
                      <>
                        {authUser.total_users_count}
                      </>
                    }
                    title={
                      <>
                        <br/>
                        <h2 className='rt-statstitle'>Allowed Players</h2>
                      </>
                    }
                  />
                </Link>
              </CCol>
              <CCol sm={6} lg={3}>
                <Link to="team/add">

                  <CWidgetStatsA
                    className="rt-stats"
                    value={
                      <>
                        {authUser.total_teams_count}
                      </>
                    }
                    title={
                      <>
                        <br/>
                        <h2 className='rt-statstitle'>Allowed Teams</h2>
                      </>
                    }
                  />
                </Link>
              </CCol>
              <CCol sm={12} lg={8}>
                {/* <div className='rt-batteryholder'>
                  <div className='rt-battery'>
                    <div className='rt-batterypercantage'>
                    </div>
                    <span>80% Users Added</span>
                  </div>
                </div> */}
                <div className='rt-themetableholder'>
                  <CTable striped>
                    <CTableHead>
                      <CTableRow>
                        <CTableHeaderCell scope="col">Name</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Email</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Team Name</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Last Login</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Action</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {/* {users.map(user => { */}
                      {/* return (<CTableRow key={user.id}> */}
                      {recentUsers.map(user => {
                        return (<CTableRow key={user.id}>
                          <CTableDataCell>
                            <Link to={'/player/'+user.id+'/profile'}>{user.full_name}</Link>
                          </CTableDataCell>
                          <CTableDataCell>{user.email}</CTableDataCell>
                          <CTableDataCell>{user.team_name}</CTableDataCell>
                          <CTableDataCell>{user.last_login}</CTableDataCell>
                          <CTableDataCell>
                            <Link to={'/player/edit/'+user.id}>
                              <CIcon icon={cilPencil} />
                            </Link>
                            <button className="delete-icon" onClick={e => deleteUser(user.id)}>
                              <CIcon icon={cilTrash} />
                            </button>
                          </CTableDataCell>
                        </CTableRow>)
                      })}

                    </CTableBody>
                  </CTable>
                </div>
              </CCol>
              <CCol sm={12} lg={4}>
                <Link to={'/player-add'}>
                  <CButton color=" rt-btn rt-btn-lg mb-4">Add Player</CButton>
                </Link>
                <Link to={'team/add'}>
                  <CButton color=" rt-btn rt-btn-lg">Add Team</CButton>
                </Link>
              </CCol>
            </CRow>

          </>
        </div>
        {/* <AppFooter /> */}
      </div>
    </div>
  )
}

export default Dashboard
