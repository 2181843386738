import React, { Component } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import './scss/style.scss'
import TeamDashboard from "./views/team-portal/TeamDashboard";
import AddPlayer from "./views/club/AddPlayer";
import AddPlayerTeam from "./views/team-portal/AddPlayer";
import AllPlayers from "./views/club/AllPlayers";
import TeamAllPlayers from "./views/team-portal/AllPlayers";
import EditTeam from "./views/team/EditTeam";
import ClubPrivateRoute from "./auth/ClubPrivateRoute";
import EditPlayer from "./views/club/EditPlayer";
import AddTeam from "./views/team/AddTeam";
import AllTeams from "./views/team/AllTeams";
import TeamPrivateRoute from "./auth/TeamPrivateRoute";
import Dashboard from "./views/club/Dashboard";
import 'axios-progress-bar/dist/nprogress.css'
import UploadFile from "./views/club/UploadFile";
import Profile from './views/profile/Profile';
import LeaderBoard from './views/club/Leaderboard';
import TeamLeaderboard from './views/team-portal/TeamLeaderboard'
import PlayerProfile from "./views/profile/PlayerProfile";
const progress = require('axios-progress-bar')

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/club/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const Forogt = React.lazy(() => import('./views/club/forgot'))
const OTP = React.lazy(() => import('./views/club/verifyOTP'))
const ResetPassword = React.lazy(() => import('./views/club/ResetPassword'))

class App extends Component {
  render() {
    progress.loadProgressBar();

    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            <Route path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
            <Route exact path="/" name="Home" render={(props) => <Login {...props} />} />

            <Route exact path="/forgot" name="forgot password" render={(props) => <Forogt {...props} />} />
            <Route exact path="/verify-otp" name="verify otp" render={(props) => <OTP {...props} />} />
            <Route exact path="/reset-password" name="Reset Password" render={(props) => <ResetPassword {...props} />} />

            <TeamPrivateRoute exact path="/team/dashboard" name="Dashboard" component={TeamDashboard} />
            <TeamPrivateRoute exact path="/team/club-leaderboard" name="Dashboard" component={LeaderBoard} />
            <TeamPrivateRoute exact path="/team/team-leaderboard" name="Dashboard" component={TeamLeaderboard} />
            <TeamPrivateRoute exact path="/team/player-add" name="AddPlayer" component={AddPlayerTeam}/>
            <TeamPrivateRoute exact path="/team/player-all" name="AddPlayer" component={TeamAllPlayers} />
            <TeamPrivateRoute exact path="/team/player/edit/:id" name="EditPlayer" component={EditPlayer} />
            {/* <TeamPrivateRoute exact path="/team/leaderboard" name="Teamleaderboard" component={TeamLeaderBoard} /> */}
            <ClubPrivateRoute exact path="/dashboard" name="Dashboard" component={Dashboard} />
            <ClubPrivateRoute exact path="/leaderboard" name="Leaderboard" component={LeaderBoard} />
            <ClubPrivateRoute exact path="/player-add" name="AddPlayer" component={AddPlayer}/>
            <ClubPrivateRoute exact path="/player-upload" name="AddPlayerFile" component={UploadFile}/>
            <ClubPrivateRoute exact path="/player-all" name="AddPlayer" component={AllPlayers} />
            <ClubPrivateRoute exact path="/player/edit/:id" name="EditPlayer" component={EditPlayer} />
            <ClubPrivateRoute exact path="/player/:id/profile" name="EditPlayer" component={PlayerProfile} />

            <ClubPrivateRoute exact path="/dashboard" name="Dashboard" component={Dashboard} />
            <ClubPrivateRoute exact path="/team/add" name="AddTeam" component={AddTeam}/>
            <ClubPrivateRoute exact path="/team/all" name="AddPlayer" component={AllTeams} />
            <ClubPrivateRoute exact path="/team/edit/:id" name="EditPlayer" component={EditTeam} />
            <ClubPrivateRoute exact path="/profile" name="Profile" component={Profile} />
            <ClubPrivateRoute exact path="/team/:teamId/leaderboard" name="TeamLeaderboard" component={TeamLeaderboard} />

          </Switch>
        </React.Suspense>
      </HashRouter>
    )
  }
}

export default App
