import React, { useCallback, useEffect, useState } from "react";

import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CRow
} from "@coreui/react";
import {  AppHeader, AppSidebar } from "../../components";
import { authService } from "../../services/auth.service";
import { dashboardService } from "../../services/dashboard.service";
import { useHistory, useParams } from "react-router-dom";
import AppSidebarTeam from "../../components/AppSidebarTeam";


const PlayerProfile = () => {

  const [userData, setUserData] = useState({
    firstName:'',
    email:'',
    password:'',
    lastName:'',
    position:'',
    gender:'',
    image: ''
  });

  const [stats,setStats] = useState({});

  let params = useParams();

  useEffect(() => {
    playerDetails()
  }, []);

  let playerDetails = () => {
    console.log('detail called');
    console.log(params);
    dashboardService.getMemberProfile(params.id).then(({ data }) => {
      console.log(data);
      if (data.status_code == 200) {
        setUserData(data.data.user)
        setStats(data.data)
      }
    })
  }

  return (
    <div>
      {authService.userInfo().role_id == 4? <AppSidebarTeam /> : <AppSidebar />}
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <div className="rt-signinformholder rt-profileformholder rt-bgcolor rt-editprofileform py-5">
            <CForm onSubmit={(e) => e.preventDefault()}>
              <CRow>
                <CCol sm={12} lg={6} style={{textAlign:'center',margin:'0px auto'}}>
                  <h2>User Information</h2>
                </CCol>
                <CCol sm={12} lg={12}>
                  <figure className="rt-roundimage rt-profileimage">
                    <img src={userData.profile_image} alt="" height={40} />

                  </figure>
                </CCol>
                <CCol sm={12} lg={6}>
                  <div className="form-group">
                    <div className="rt-fieldholder rt-fieldwithlabel">
                      <CFormInput type="text0" name={'disctracted'} id="exampleInput1" value={userData.full_name}  aria-describedby="emailHelp" disabled={true}/>
                      <CFormLabel htmlFor="exampleInput1">FULL NAME</CFormLabel>
                    </div>
                  </div>
                </CCol>
                <CCol sm={12} lg={6}>
                  <div className="form-group">
                    <div className="rt-fieldholder rt-fieldwithlabel">
                      <CFormInput type="text" name={'disctracted'} id="exampleInput1" value={userData.email} disabled={true}/>
                      <CFormLabel htmlFor="exampleInput1">Email</CFormLabel>
                    </div>
                  </div>
                </CCol>
                <CCol sm={12} lg={6}>
                  <div className="form-group">
                    <div className="rt-fieldholder rt-fieldwithlabel">
                      <CFormInput type="text" name={'disctracted'} id="exampleInput1" value={userData.user_team != null ? userData.user_team.full_name : ''} disabled={true}/>
                      <CFormLabel htmlFor="exampleInput1">Team name</CFormLabel>
                    </div>
                  </div>
                </CCol>

                <CCol sm={12} lg={6}>
                  <div className="form-group">
                    <div className="rt-fieldholder rt-fieldwithlabel">
                      <CFormInput type="text" name={'disctracted'} id="exampleInput1" value={stats.recently_completed} disabled={true}/>
                      <CFormLabel htmlFor="exampleInput1">Recently Completed</CFormLabel>
                    </div>
                  </div>
                </CCol>

                <CCol sm={12} lg={6}>
                  <div className="form-group">
                    <div className="rt-fieldholder rt-fieldwithlabel">
                      <CFormInput type="text" name={'disctracted'} id="exampleInput1" value={stats.total_sessions} disabled={true}/>
                      <CFormLabel htmlFor="exampleInput1">Leaderboard Points</CFormLabel>
                    </div>
                  </div>
                </CCol>

                <CCol sm={12} lg={6}>
                  <div className="form-group">
                    <div className="rt-fieldholder rt-fieldwithlabel">
                      <CFormInput type="text" name={'disctracted'} id="exampleInput1" value={stats.passes} disabled={true}/>
                      <CFormLabel htmlFor="exampleInput1">Passes</CFormLabel>
                    </div>
                  </div>
                </CCol>

                <CCol sm={12} lg={6}>
                  <div className="form-group">
                    <div className="rt-fieldholder rt-fieldwithlabel">
                      <CFormInput type="text" name={'disctracted'} id="exampleInput1" value={stats.shots} disabled={true}/>
                      <CFormLabel htmlFor="exampleInput1">Shots</CFormLabel>
                    </div>
                  </div>
                </CCol>

                <CCol sm={12} lg={6}>
                  <div className="form-group">
                    <div className="rt-fieldholder rt-fieldwithlabel">
                      <CFormInput type="text" name={'disctracted'} id="exampleInput1" value={stats.touches} disabled={true}/>
                      <CFormLabel htmlFor="exampleInput1">Touches</CFormLabel>
                    </div>
                  </div>
                </CCol>

              </CRow>
            </CForm>
          </div>
        </div>
        {/* <AppFooter /> */}
      </div>
    </div>
  )
}

export default PlayerProfile
