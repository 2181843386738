import { HttpService } from "./base.service";
import APIEndpoints from "./APIEndpoints";
import {authService} from "./auth.service";

class DashboardService extends HttpService {
  constructor() {
    super();
    HttpService.setToken(authService.authToken())
    this.prefix = 'v2/club/';
  }
  /**
   * User Crud Operations
   */
  createUser = (data) => this.post(`${this.prefix}${APIEndpoints.CREATE_PLAYER}`,data);

  createUserByTeam = (data) => this.post(`${this.prefix}${APIEndpoints.CREATE_PLAYER_BY_TEAM}`,data);

  uploadUsers = (data) => this.post(`${this.prefix}${APIEndpoints.UPLOAD_PLAYER}`,data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }});

  updateUser = (data,id) => this.post(`${this.prefix}${APIEndpoints.UPDATE_PLAYERS}${id}`,data);

  allPlayers = () => this.get(`${this.prefix}${APIEndpoints.PLAYERS}`);

  allPlayersForTeamCreate = (creating = 0) => this.get(`${this.prefix}${APIEndpoints.PLAYERS}?team_create=1&creating=${creating}`);

  teamAllPlayers = () => this.get(`${this.prefix}${APIEndpoints.TEAM_PLAYERS}`);

  getUserDetails = (id) => this.get(`${this.prefix}${APIEndpoints.PLAYER_DETAILS}${id}`);

  getMemberProfile = (id) => this.get(`member/${id}/profile`);

  deletePlayer = (id) => this.post(`${this.prefix}${APIEndpoints.DELETE_PLAYER}${id}`);

  removePlayerFromTeam = (id) => this.post(`${this.prefix}${APIEndpoints.REMOVE_PLAYER_FROM_TEAM}${id}`);

  /**
   * Team Crud Operations
   */
  createTeam = (data) => this.post(`${this.prefix}${APIEndpoints.CREATE_TEAM}`,data);

  updateTeam = (data,id) => this.post(`${this.prefix}${APIEndpoints.UPDATE_TEAMS}${id}`,data);

  allTeams = () => this.get(`${this.prefix}${APIEndpoints.TEAMS}`);

  getTeamDetails = (id) => this.get(`${this.prefix}${APIEndpoints.TEAM_DETAILS}${id}`);

  deleteTeam = (id) => this.post(`${this.prefix}${APIEndpoints.DELETE_TEAM}${id}`);

  countries = (data) => this.get(`${APIEndpoints.COUNTRIES}`,data);

  updateProfile = (data) => this.post(`${APIEndpoints.UPDATE_PROFILE}`,data)

}

export const dashboardService = new DashboardService();
