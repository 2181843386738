import { HttpService } from "./base.service";
import APIEndpoints from "./APIEndpoints";

class AuthService extends HttpService {
  constructor() {
    super();
    this.prefix = 'v2/club/'
  }

  /**
   * Basic Authenticate User
   * @param data
   */
  login = (data) => this.post(`${this.prefix}${APIEndpoints.LOGIN}`, data);

  forgotPassword = (data) => this.post(`${APIEndpoints.ForgotPasswordSendEmail}`, data);
  verifyCode = (data) => this.post(`${APIEndpoints.VerifyOTPCode}`, data);

  updatePassword = (data) => this.post(`${APIEndpoints.UpdatePassword}`, data);
  privatePost = ({url, data}) => {
    HttpService.setToken(this.authToken());

    return this.post(`${this.prefix}${url}`, data);
  }

  privateGet = ({url, query}) => {
    HttpService.setToken(this.authToken());

    return this.get(`${this.prefix}${url}?${query}`);
  }

  /**
   * Authenticate User
   * @param data
   */

  authenticate = async ({token, user}) => {
    sessionStorage.setItem('isAuthenticated',String(true));
    await sessionStorage.setItem('token',token);
    sessionStorage.setItem("user",JSON.stringify(user))
    if(user.role_id === 3){
      sessionStorage.setItem("isClub",String(true))
    }else if(user.role_id === 4){
      sessionStorage.setItem("isTeam",String(true));
    }
  }

  isAuthenticated = () => {
    return (sessionStorage.getItem('isAuthenticated') != undefined && sessionStorage.getItem('isAuthenticated') == 'true')

  }

  setUpdatedUserInfo = async (user ) => {
    sessionStorage.setItem("user",JSON.stringify(user))
  }

  username = () => {
    let user = JSON.parse(sessionStorage.getItem("user"))

    return user.name;
  }

  userInfo = () => {
    let user = JSON.parse(sessionStorage.getItem("user"))

    return user;
  }

  userId = () => {
    let user = JSON.parse(sessionStorage.getItem("user"))

    return user.id;
  }

  isClub = () => {
    return (sessionStorage.getItem("isClub") != undefined && sessionStorage.getItem("isClub") === 'true')

  }

  isTeam = () => {
    return (sessionStorage.getItem("isTeam") != undefined && sessionStorage.getItem("isTeam") === 'true')

  }

  authToken = () => {
    return sessionStorage.getItem('token') ?? ''
  }

  signout = () => {
    sessionStorage.clear();
    window.location.href = '/';
  }

}

export const authService = new AuthService();
