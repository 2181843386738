
export const APP_DOMAIN = 'https://admin.mysoccertraining.com'

//export const APP_DOMAIN = 'https://app.staging-be.mysoccertraining.com'
export const APP_URL = 'http://localhost:3000'
export const API_ENDPOINT = `${APP_DOMAIN}/api`
export const ASSET_ENDPOINT = `${APP_DOMAIN}/`
export const ASSET_ENDPOINT_LIVE = `https://admin.mysoccertraining.com`
export const CURRENT_VERSION = 'x.x.x'
export const THIRD_PARTY = {
  facebook: {
    appID: '588919188707106',
  },
  instagram: {
    appID: '1142995602709632',
    appSecret: '0b1c123482e6ca0f274a7f0ef770c199',
  },
}
export const PAYMENT_METHOD = {
  paypal: 'paypal',
  chargebee: 'chargebee',
}
