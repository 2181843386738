import React, { lazy, useState } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  CAvatar,
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CForm, CFormCheck, CFormInput, CFormLabel, CFormText,
  CProgress,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow, CWidgetStatsA
} from "@coreui/react";
import { CChartBar, CChartLine } from "@coreui/react-chartjs";
import { getStyle, hexToRgba } from '@coreui/utils'
import CIcon from '@coreui/icons-react'
import {
  cibCcAmex,
  cibCcApplePay,
  cibCcMastercard,
  cibCcPaypal,
  cibCcStripe,
  cibCcVisa,
  cibGoogle,
  cibFacebook,
  cibLinkedin,
  cifBr,
  cifEs,
  cifFr,
  cifIn,
  cifPl,
  cifUs,
  cibTwitter,
  cilCloudDownload,
  cilPeople,
  cilUser,
  cilCloudUpload,
  cilUserFemale, cilArrowBottom, cilOptions, cilArrowTop, cilPencil, cilTrash
} from "@coreui/icons";

import avatar1 from 'src/assets/images/avatars/1.jpg'
import avatar2 from 'src/assets/images/avatars/2.jpg'
import avatar3 from 'src/assets/images/avatars/3.jpg'
import avatar4 from 'src/assets/images/avatars/4.jpg'
import avatar5 from 'src/assets/images/avatars/5.jpg'
import avatar6 from 'src/assets/images/avatars/6.jpg'
import { AppContent, AppFooter, AppHeader, AppSidebar } from "../../components";
import { Link, useHistory } from "react-router-dom";
import { dashboardService } from "../../services/dashboard.service";
import { APP_DOMAIN } from "../../environment";

const UploadFile = () => {

  const [file,setFile] = useState({})
  const [response,setResponse] = useState([])
  const [responseMessage, setResponseMessage] = useState('');
  let history = useHistory();
  let addPlayer = (e) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append('file', file);
    console.log('>> formData >> ', formData);

    // You should have a server side REST API
    dashboardService.uploadUsers(formData).then(({data}) => {
      console.log(data);
      if (data.status_code == 200){
        setResponseMessage(data.response.importMessage);
        setResponse(data.response.response);
      }else{
        alert(data.message ?? 'Please select a file')
      }
    })

  }

  let exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "MST CLUB Created User Report";
    const headers = [["First Name","Last Name","Email", "Password","Response"]];

    const data = response.map(elt=> [elt.first_name,elt.last_name,elt.username,elt.email,elt.password, elt.message]);

    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("MST CLUB User Created Report.pdf")
  }

  let handleFileUpload = (e) => {
    setFile(e.target.files[0]);

  }

  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <div className="rt-signinformholder rt-uplodfileformholder py-5">
            <CForm onSubmit={e => addPlayer(e)}>
              <CRow>
                <div className="rt-uploadfileholder">
                  <CFormLabel>
                    <CFormInput type="file" id="exampleInput1" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" aria-describedby="emailHelp" onChange={e => handleFileUpload(e)}/>
                    <CIcon icon={cilCloudUpload} size="lg" />
                    <span>{file && file.name ? file.name+'('+(file.size/1000)+ 'KB)': 'Upload File'} </span>
                  </CFormLabel>
                </div>
                <CCol sm={12} lg={6} className="mb-4">
                  <a className="rt-btn rt-btn-lg rt-black-btn" href={APP_DOMAIN+'/Club-User-Template.xlsx'} target="_blank" rel="noreferrer" >Download Sample</a>
                </CCol>
                <CCol sm={12} lg={6} className="">
                <CButton type="submit" color=" rt-btn rt-btn-lg px-4 py-0 border-0">Upload</CButton>
                </CCol>
              </CRow>
            </CForm>
          </div>
          {responseMessage != '' ? <>
          <h3>{responseMessage} <button className="btn btn- rt-btn" onClick={exportPDF} style={{
            maxHeight: '65px',
            fontSize: 'initial',
            font: 'icon',
            marginBottom: '20px',
            }}>Download Report in PDF</button></h3>
          <div className="rt-themetableholder">
            <CTable striped>
              <CTableHead>
                <CTableRow>
                  {/* <CTableHeaderCell scope="col">#</CTableHeaderCell>s */}
                  <CTableHeaderCell scope="col">First Name</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Last Name</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Email</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Password</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Response</CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                {response.map((message) => {
                  return (<CTableRow key={message.email}>
                    {/* <CTableHeaderCell scope="row">{user.id}</CTableHeaderCell> */}
                    <CTableDataCell style={message.message.includes('exists') ? {color:'red'} : {}}>{message.first_name}</CTableDataCell>
                    <CTableDataCell style={message.message.includes('exists') ? {color:'red'} : {}}>{message.last_name}</CTableDataCell>
                    <CTableDataCell style={message.message.includes('exists') ? {color:'red'} : {}}>{message.email}</CTableDataCell>
                    <CTableDataCell style={message.message.includes('exists') ? {color:'red'} : {}}>{  message.password }</CTableDataCell>
                    <CTableDataCell style={message.message.includes('exists') ? {color:'red'} : {}}>{message.message}</CTableDataCell>
                  </CTableRow>)
                })}
              </CTableBody>
            </CTable>
          </div>
          </> : ''}

        </div>
        {/* <AppFooter /> */}
      </div>
    </div>
  )
}

export default UploadFile
