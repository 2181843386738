import React, { lazy, useEffect, useState } from "react";

import {
  CAvatar,
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CForm, CFormCheck, CFormInput, CFormLabel, CFormText,
  CProgress,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow, CWidgetStatsA
} from "@coreui/react";
import { CChartBar, CChartLine } from "@coreui/react-chartjs";
import { getStyle, hexToRgba } from '@coreui/utils'
import CIcon from '@coreui/icons-react'
import {
  cibCcAmex,
  cibCcApplePay,
  cibCcMastercard,
  cibCcPaypal,
  cibCcStripe,
  cibCcVisa,
  cibGoogle,
  cibFacebook,
  cibLinkedin,
  cifBr,
  cifEs,
  cifFr,
  cifIn,
  cifPl,
  cifUs,
  cibTwitter,
  cilCloudDownload,
  cilPeople,
  cilUser,
  cilUserFemale, cilArrowBottom, cilOptions, cilArrowTop,cilTrash,cilPencil
} from "@coreui/icons";

import avatar1 from 'src/assets/images/avatars/1.jpg'
import avatar2 from 'src/assets/images/avatars/2.jpg'
import avatar3 from 'src/assets/images/avatars/3.jpg'
import avatar4 from 'src/assets/images/avatars/4.jpg'
import avatar5 from 'src/assets/images/avatars/5.jpg'
import avatar6 from 'src/assets/images/avatars/6.jpg'
import { AppContent, AppFooter, AppHeader, AppSidebar } from "../../components";
import { Link, useHistory } from "react-router-dom";
import { dashboardService } from "../../services/dashboard.service";
// import { authService } from "../services/auth.service";

const AllPlayers = () => {

  const [users, setUsers] = useState([]);
  let history = useHistory();

  useEffect(() => {
    allPlayers()
  }, []);

  let allPlayers = () => {
    dashboardService.allPlayers().then(({data}) => {
      console.log(data);
      if (data.status_code == 200){
        setUsers(data.users.data)
        console.log(data.users.data);
      }
    })

  }

  let deleteUser = (id) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Do you really want to delete?') ===  true){
      dashboardService.deletePlayer(id).then(({ data }) => {
        console.log(data);
        if (data.status_code == 200){
          allPlayers()
        }
      })
    }
  }

  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <div className="rt-themetableholder">
            <CTable striped>
              <CTableHead>
                <CTableRow>
                  {/* <CTableHeaderCell scope="col">#</CTableHeaderCell>s */}
                  <CTableHeaderCell scope="col"></CTableHeaderCell>
                  <CTableHeaderCell scope="col">Name</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Email</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Team Name</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Last Login</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Action</CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                {users.map(user => {
                  return (<CTableRow key={user.id}>
                      {/* <CTableHeaderCell scope="row">{user.id}</CTableHeaderCell> */}
                    <CTableDataCell>
                      <figure className='rt-roundimage'>
                         <img src={user.profile_image} alt="" height={40} />
                      </figure>
                    </CTableDataCell>
                      <CTableDataCell>
                        <Link to={'/player/'+user.id+'/profile'}>{user.full_name}</Link>
                      </CTableDataCell>
                      <CTableDataCell>{user.email}</CTableDataCell>
                      <CTableDataCell>{user.team_name}</CTableDataCell>
                      <CTableDataCell>{user.last_login}</CTableDataCell>

                      <CTableDataCell>
                        <Link to={'/player/edit/'+user.id}>
                          <CIcon icon={cilPencil} />
                        </Link>
                        <button className="delete-icon" onClick={e => deleteUser(user.id)}>
                          <CIcon icon={cilTrash} />
                        </button>
                      </CTableDataCell>
                    </CTableRow>)
                })}
              </CTableBody>
            </CTable>
          </div>
        </div>
        {/* <AppFooter /> */}
      </div>
    </div>
  )
}

export default AllPlayers
