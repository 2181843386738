import React, { lazy, useEffect, useState } from "react";

import {
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow, CWidgetStatsA
} from "@coreui/react";
import CIcon from '@coreui/icons-react'
import {
  cilChart, cilPencil, cilTrash
} from "@coreui/icons";

import { AppFooter, AppHeader, AppSidebar } from "../../components";
import { Link, useHistory } from "react-router-dom";
import { dashboardService } from "../../services/dashboard.service";

const AllTeams = () => {

  const [users, setUsers] = useState([]);
  let history = useHistory();

  useEffect(() => {
    allPlayers()
  }, []);

  let allPlayers = () => {
    dashboardService.allTeams().then(({data}) => {
      console.log(data);
      if (data.status_code == 200){
        setUsers(data.users.data)
        console.log(data.users.data);
      }
    })

  }

  let deleteUser = (id) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Do you really want to delete?') ===  true){
      dashboardService.deleteTeam(id).then(({ data }) => {
        console.log(data);
        if (data.status_code == 200){
          allPlayers()
        }
      })
    }
  }

  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <div className="rt-themetableholder">
            <CTable striped>
              <CTableHead>
                <CTableRow>
                  {/* <CTableHeaderCell scope="col">#</CTableHeaderCell> */}
                  <CTableHeaderCell scope="col">Name</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Email</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Action</CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                {users.map(user => {
                  return (<CTableRow key={user.id}>
                    {/* <CTableHeaderCell scope="row">{user.id}</CTableHeaderCell> */}
                    <CTableDataCell>{user.full_name}</CTableDataCell>
                    <CTableDataCell>{user.email}</CTableDataCell>
                    <CTableDataCell>
                      <Link to={'/team/edit/'+user.id}>
                        <CIcon icon={cilPencil} />
                      </Link>
                      <Link to={'/team/'+user.id+'/leaderboard'}>
                        <CIcon icon={cilChart} />
                      </Link>

                      <button onClick={e => deleteUser(user.id)} className="delete-icon">
                        <CIcon icon={cilTrash} />
                      </button>
                    </CTableDataCell>
                  </CTableRow>)
                })}
              </CTableBody>
            </CTable>
          </div>
        </div>
        {/* <AppFooter /> */}
      </div>
    </div>
  )
}

export default AllTeams
